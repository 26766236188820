import { afterNextRender, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, signal, viewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { ResizeService, AuthService } from '../../auth.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PaintDirective } from '../../paint.directive';
import { LocationService } from '../../location.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cloudigo-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatExpansionModule, MatIcon, RouterLink, PaintDirective]
})
export class FooterComponent {
  size = inject(ResizeService);
  router = inject(LocationService);
  toast = inject(ToastrService);
  auth = inject(AuthService);

  emailField: FormControl = new FormControl('', [Validators.required, Validators.email]);
  year?: string;

  element = viewChild<ElementRef<HTMLDivElement>>('footer');

  debugCounter = 0;
  debugEvent: EventEmitter<void> = new EventEmitter<void>();

  forcedOpen = signal<boolean>(true);

  calculateState = () => {
    const element = this.element();
  
    if(element?.nativeElement){
      this.forcedOpen.set((element.nativeElement.getBoundingClientRect()?.width) > 650);
    }
  }

  constructor() { 
    this.year = new Date().getFullYear().toString();
    this.size.resizer.pipe(takeUntilDestroyed()).subscribe(() => {
      this.calculateState();
    });

    afterNextRender(() => this.calculateState());

    this.debugEvent.pipe().subscribe(d => {
      this.debugCounter++;

      if(this.debugCounter >= 5){
        let msg = ``; 

        if(this.router.initialURL){
          msg+= 'Query: ';
          this.router.initialURL.searchParams.forEach((v,k) => msg += `${k}: ${v} | `);
        }
        
        this.toast.info(msg, 'Info', {
          disableTimeOut: true
        });
      }
    });
    this.debugEvent.pipe(takeUntilDestroyed(), debounceTime(3000)).subscribe(d => this.debugCounter = 0);

  }

}
