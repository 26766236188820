import { APP_INITIALIZER, ApplicationConfig, DEFAULT_CURRENCY_CODE, ErrorHandler, importProvidersFrom, inject, provideZoneChangeDetection } from '@angular/core';
import { ActivatedRouteSnapshot, provideRouter, Router, RouterStateSnapshot, withComponentInputBinding, withInMemoryScrolling, withRouterConfig } from '@angular/router';

import { routes } from './app.routes';
import { HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FirebaseOptions, getApp, initializeApp, initializeServerApp, provideFirebaseApp } from '@angular/fire/app';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { initializeAppCheck, provideAppCheck, ReCaptchaEnterpriseProvider, ReCaptchaV3Provider } from '@angular/fire/app-check';
import { REQUEST } from 'ngx-cookie-service-ssr';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { backend, firebase_options, recaptcha_site_key } from '../environments/environment';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { provideToastr } from 'ngx-toastr';
import { catchError, from, of, switchMap, throwError } from 'rxjs';
import { provideClientHydration, withEventReplay, withHttpTransferCacheOptions } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideNgxStripe } from 'ngx-stripe';
import * as Sentry from "@sentry/angular";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/i18n/', '.json?v=4');
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
      }),
      withComponentInputBinding(),
    ), 
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includeRequestsWithAuthHeaders: true,
        // filter: (req) => !req.url.includes('/element')
      }),
    ),
    provideAnimationsAsync(),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
     },
     defaultLanguage: 'en'
    })),
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    provideHttpClient(
      withFetch(),
      withInterceptors([
        (req, next) => {
          const auth = inject(Auth, { optional: true });

          if(req.url.startsWith(backend) && auth){
            return from(auth.authStateReady()).pipe(
              switchMap(() => {
                // if(!auth.currentUser){
                //   console.log(req.url, 'No User');
                // }
                if(auth.currentUser){
                  return from(auth.currentUser.getIdToken())
                } else {
                  return of(null);
                }
              }),
              switchMap(token => token ? next(req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) })) : next(req)),
              catchError(error => {
                let result = error;
                if(!error.status || error.status === 0){
                  result = new Error('Could not connect to cloudigo servers'); 
                } else if(error.status >= 500 && error.status < 600){
                  result = new Error('Failed to connect to cloudigo servers');
                } else {
                  result = error.error;
                }
                return throwError(() => result);
              })
            );
          }

          return next(req).pipe(catchError(error => {
            let result = error;
            if(!error.status || error.status === 0){
              result = new Error('Could not connect to cloudigo servers'); 
            } else if(error.status >= 500 && error.status < 600){
              result = new Error('Failed to connect to cloudigo servers');
            } else {
              result = error.error;
            }
            return throwError(() => result);
          }));

        }
      ])
    ), 
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: recaptcha_site_key
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-MT'
    },
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        if(!inject(Platform).isBrowser){
          return false;
        }
        
        const document = inject(DOCUMENT);

        const currentPath = state.url.replace('/' + route.routeConfig?.path, '').split('?')[0];

        //! https://play.google.com/store/apps/details?id=app.cloudigo.cloudigo#from_web
        const [externalPath, externalFragment] = route.data.externalUrl.split('#');
        //! https://play.google.com/store/apps/details?id=app.cloudigo.cloudigo from_web
        const [externalUrl, externalQueryParams] = externalPath.split('?');
        //! https://play.google.com/store/apps/details id=app.cloudigo.cloudigo

        const currentSearch = new URLSearchParams(route.queryParams);
        const finalSearch = new URLSearchParams(externalQueryParams);

        currentSearch.forEach((v, k) => {
          if(!finalSearch.has(k)){
            finalSearch.append(k, v)
          }
        });
        
        const finalQueryParams = finalSearch.toString().replace(/^[^?]/, match => `?${match}`);;
        const finalFragment = (externalFragment || route.fragment || '').replace(/^[^#]/, (match: string) => `#${match}`);
        const targetPath = `${externalUrl}${currentPath}${finalQueryParams}${finalFragment}`;

        document.location.href = targetPath;
        return false;
      }
    },
    provideNgxStripe(),
    provideFirebaseApp(() => {
      return initializeApp(firebase_options);
      //?For SSR:
      // if(inject(Platform).isBrowser){
      //   return initializeApp(firebase_options);
      // }

      // const request = inject(REQUEST, { optional: true });
      // const authIdToken = request?.headers.authorization?.split("Bearer ")[1];
      // return initializeServerApp(firebase_options, {
      //   authIdToken,
      //   releaseOnDeref: request || undefined
      // });
    }), 
    provideAuth(() => getAuth()),
    provideToastr({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      autoDismiss: true,
      maxOpened: 3,
      progressBar: true,
    }),
    provideAnalytics(() => getAnalytics()), 
    ScreenTrackingService, 
    UserTrackingService, 
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  ]
};
