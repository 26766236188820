import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PaintDirective } from '../paint.directive';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ScrollService, AuthService, ResizeService } from '../auth.service';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatRipple } from '@angular/material/core';
import { from, lastValueFrom, map, skipWhile, take } from 'rxjs';
import { LocationService } from '../location.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cloudigo-nav-bar',
  standalone: true,
  imports: [PaintDirective, RouterLink, RouterLinkActive, MatRipple, MatIconModule, MatButtonModule],
  templateUrl: './navigation-bar.component.html',
  styleUrl: './navigation-bar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('scrollDirection', [
      state('1', style({
          'transform': 'translateY(0px)'
      })),
      state('0', style({
          'transform': 'translateY(-54px)'
      })),
      transition('1 <=> 0', [
          animate("250ms cubic-bezier(0.455, 0.03, 0.515, 0.955)")
      ]),
    ])
  ]
})
export class NavigationBarComponent {

  auth = inject(AuthService);
  scroll = inject(ScrollService);
  resize = inject(ResizeService);
  router = inject(LocationService);
  translate = inject(TranslateService);
  

  threshold: number = 100;

  inSearch = computed(() => this.router.currentURL().pathname.includes('/search'));

  isVisible = signal<boolean>(true);
  authLoaded = toSignal(from(this.auth.fireAuth.authStateReady()).pipe(map(() => true)), { initialValue: false});

  constructor(){
    this.scroll.scroller.pipe(takeUntilDestroyed(), skipWhile(() => this.resize.width() < 900)).subscribe(({ position, direction }) => {
      if(direction == 'up'){
        this.isVisible.set(true);
      } else {

        if(position > this.threshold){
          this.isVisible.set(false);
        }
      }
    });
  
  }

}
