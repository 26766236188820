<footer #footer>
    @let unexpandable = forcedOpen();
    @if(auth.userSession()){
        <mat-expansion-panel class="footer-section mat-elevation-z0" [expanded]="unexpandable" [hideToggle]="unexpandable" [disabled]="unexpandable">
            <mat-expansion-panel-header>
                <div class="cloudigo-logo">
                    <mat-icon class="sized" svgIcon="logo:cloudigo"></mat-icon>
                    <span paint="black">cloudigo<span style="font-size: 16px;">&reg;</span></span>
                </div>
            </mat-expansion-panel-header>
            <section>
                <h3 class="my-6 font-bold" paint="black">My cloudigo</h3>
                <a routerLink="/profile/">Profile</a>
                <a routerLink="/profile/preferences">Preferences</a>
                <a routerLink="/profile/redemptions">Redemption History</a>
            </section>
        </mat-expansion-panel>
    } @else {
        <mat-expansion-panel class="footer-section mat-elevation-z0" [expanded]="true" [hideToggle]="true" [disabled]="true">
            <mat-expansion-panel-header>
                <div class="cloudigo-logo">
                    <mat-icon class="sized mr-5" svgIcon="logo:cloudigo"></mat-icon>
                    <span paint="black">cloudigo<span style="font-size: 16px;">&reg;</span></span>
                </div>
            </mat-expansion-panel-header>
            <section class="not-authed">
                <a routerLink="/signup">Start saving money now by joining the cloudigo community for free.</a>
    
                <div class="store-ctas">
                    <a target="_top" rel="noreferrer" href="https://play.google.com/store/apps/details?id=app.cloudigo.cloudigo">
                        <img src="old/playstore-btn.png" alt="Get it on the Playstore"/>
                    </a>
                    <a target="_top" rel="noreferrer" href="https://apps.apple.com/mt/app/cloudigo/id1440612925">
                        <img src="old/appstore-btn.png" alt="Get it on the Appstore"/>
                    </a>
                </div>
            </section>
        </mat-expansion-panel>
    }
    
    <mat-expansion-panel class="footer-section mat-elevation-z0" [expanded]="unexpandable" [hideToggle]="unexpandable" [disabled]="unexpandable">
        <mat-expansion-panel-header>
            <h3 class="m-0 font-bold" paint="black">Consumer</h3>
        </mat-expansion-panel-header>
        <section>
            <a routerLink="/about-us">About Us</a>
            <a routerLink="/how-to/redeem">How to use cloudigo</a>
            <a routerLink="/how-to/premium">How to get Premium</a>
            <a routerLink="/how-to/gift">How to find and redeem Gifts</a>
            <a routerLink="/premium">Get Premium</a>
            @if(!auth.isTourist()){
                <!-- <a routerLink="/premium/yearly/calculator">Savings Calculator</a> -->
                <a routerLink="/premium/gift">Buy Premium as a Gift</a>
            }
            <a routerLink="/traveller">cloudigo for tourists</a>
            <a routerLink="/contact-us">Contact Us</a>
        </section>
    </mat-expansion-panel>

    <mat-expansion-panel class="footer-section mat-elevation-z0" [expanded]="unexpandable" [hideToggle]="unexpandable" [disabled]="unexpandable">
        <mat-expansion-panel-header>
            <h3 class="m-0 font-bold" paint="black">Business</h3>
        </mat-expansion-panel-header>
        <section>
            <a routerLink="/business/pages/corporates.html">Employee Giftcards</a>
            <a routerLink="/business/pages/promote_business.html">Become a Merchant</a>
            <a routerLink="/business/pages/competitions.html">Games & Competitions</a>
        </section>
    </mat-expansion-panel>

    <mat-expansion-panel class="footer-section mat-elevation-z0" [expanded]="unexpandable" [hideToggle]="unexpandable" [disabled]="unexpandable">
        <mat-expansion-panel-header>
            <h3 class="m-0 font-bold" paint="black">Legal</h3>
        </mat-expansion-panel-header>
        <section>
            <a routerLink="/privacy-policy">Privacy Policy</a>
            <a routerLink="/terms-and-conditions">Terms & Conditions</a>
            <a routerLink="/terms-and-conditions/discounts">Promotional Terms</a>
            <a routerLink="/terms-and-conditions/social">Competition Terms</a>
            <a routerLink="/cookies">Cookies</a>
            <a routerLink="/faqs">F.A.Qs</a>
        </section>
    </mat-expansion-panel>

    <!-- <mat-divider class="divide"></mat-divider> -->
    <div class="footer-bottom">
        <div>
            <a class="social-media-button" href="https://www.facebook.com/cloudigo" target="_blank">
                <mat-icon class="sized" svgIcon="logo:facebook">
                </mat-icon>
            </a>
            <a class="social-media-button" href="https://www.instagram.com/cloudigomt" target="_blank">
                <mat-icon class="sized" svgIcon="logo:instagram">
                </mat-icon>
            </a>
            <a class="social-media-button" href="https://www.youtube.com/channel/UCx0P2nVwtv6Dyk-NV-JElVQ" target="_blank">
                <mat-icon class="sized" svgIcon="logo:youtube">
                </mat-icon>
            </a>
            <a class="social-media-button" href="https://mt.linkedin.com/company/cloudigomt" target="_blank">
                <mat-icon class="sized" svgIcon="logo:linkedin">
                </mat-icon>
            </a>
            <a class="social-media-button" href="https://www.tiktok.com/@cloudigomt" target="_blank">
                <mat-icon class="sized" svgIcon="logo:tiktok">
                </mat-icon>
            </a>
        </div>
        <div>
            <p>&copy; {{year}} cloudigo, All <span (click)="debugEvent.next()">rights</span> reserved</p>
        </div>
    </div>
</footer>
