import { afterNextRender, ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from './old/footer/footer.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { CacheService, AuthService } from './auth.service';
import { LocationService } from './location.service';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { MetaService } from './meta.service';
import { register } from 'swiper/element-bundle';
import { DOCUMENT } from '@angular/common';
import { DateTime } from 'luxon';
import { environment } from '../environments/environment';

register();

@Component({
  selector: 'cloudigo-root',
  standalone: true,
  imports: [RouterOutlet, NavigationBarComponent,FooterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  host: {
    '[style.--plan-background-color]': 'planBackgroundColor()',
    '[style.--plan-foreground-color]': 'planForegroundColor()',
    '[style.--plan-gradient-horizontal]': 'planGradientHorizontal()',
    '[style.--plan-gradient-vertical]': 'planGradientVertical()',
    '[@routeAnimations]': 'location.currentURL().pathname',
    '[class.from-app]': 'location.isFromApp()',
    '[class.christmas]': 'isChristmas()'
  },
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        query('@routeAnimations>:enter, @routeAnimations>:enter ~ cloudigo-footer', [
            style({
                display: 'block',
                opacity: 0,
                transform: 'translateY(50vh)'
            }),
            animate('700ms ease', style({
              opacity: 1,
              transform: 'translateY(0)'
          })),
        ], { optional: true }),
      ])
   ])
  ]
})
export class AppComponent {
  auth = inject(AuthService);//!Here to load auth state
  location = inject(LocationService);//!Here to log inital navigation route
  cache = inject(CacheService);//!Here to register icons
  meta = inject(MetaService);
  document = inject(DOCUMENT);

  isChristmas = signal<boolean>(false);

  planBackgroundColor = computed(() => this.auth.userSession()?.colors?.background || '#0075ff');
  planForegroundColor = computed(() => this.auth.userSession()?.colors?.foreground || '#ffffff');
  planGradientHorizontal = computed(() => this.auth.userSession()?.colors?.gradient_horizontal || 'linear-gradient(90deg, #2EABFF 0%, #0075ff 100%)');
  planGradientVertical = computed(() => this.auth.userSession()?.colors?.gradient_vertical || 'linear-gradient(180deg, #2EABFF 0%, #0075ff 100%)');

  constructor(){
    this.meta.setTags();
    const [ rightNow, start, end ] = [DateTime.now(), DateTime.fromISO(`2024-${environment == 'production' ? '12' : '11'}-01`), DateTime.fromISO('2025-01-01')];

    if(rightNow > start && rightNow < end){
      this.isChristmas.set(true);
      afterNextRender(() => {
        setTimeout(() => {
          const snow = this.document.createElement('script');
          snow.innerText = 'r=Math.random;a=document.getElementById("snowfall-element");b=a.getContext("2d");c=function(){t=this;t.a=r()*4+3;t.b=(r()*w-t.a-1)+t.a+1;t.c=t.b;t.d=r()*50+1;t.e=r();t.f=r()*Math.PI*2;t.g=r()*1.5+0.5;t.i=(r()*h-t.a-1)+t.a+1-h;t.j=()=>{t=this;if(t.i>h+t.a){t.i=-t.a}else{t.i+=t.g}t.f+=0.02;t.b=t.c+t.d*Math.sin(t.f);b.fillStyle=`rgba(255,255,255,${t.e})`;b.beginPath();b.arc(t.b,t.i,t.a,t.a,4*Math.PI);b.fill();}};e=()=>{a.width=w=b.canvas.clientWidth;a.height=h=b.canvas.clientHeight;d=[];for(x=0;x<Math.ceil((w*h)/15000);x+=1){d.push(new c())}};window.addEventListener("resize",e);f=()=>{requestAnimationFrame(f);b.clearRect(0,0,w,h);for(x of d){x.j()}};e();f();';
    
          this.document.body.append(snow);
        }, 1000);
      })
    }
  }

}
