<nav class="desktop" [@scrollDirection]="isVisible()">
    <ul class="m-0 px-10 p-inset cgap-8 f-cross-center">
        <li>
            <a mat-button paint="black" routerLink="/">
                <mat-icon svgIcon="logo:cloudigo" class="sized" style="--icon-size: 44px;"></mat-icon>
                <span class="font-semibold">cloudigo®</span>
            </a>    
        </li>
        <li class="search">
            @if(!inSearch()){
                <a mat-button [routerLink]="['/search']">
                    <mat-icon>search</mat-icon>
                    <span paint="dark">Search for brands</span>
                </a>    
            }
        </li>
        <li>
            <a mat-button paint="black" routerLink="/business">Business</a>
        </li>
        @if(authLoaded()){
            @if(auth.userSession()){
                <li>
                    <a mat-button [routerLink]="['/profile']" paint="plan-bg">My Profile</a>
                </li>
            } @else {
                <li>
                    <a mat-button paint="dark" [routerLink]="['/login']">Login/Register</a>
                </li>
            }
        }
        @if(!auth.isPremium()){
            <li>
                <a mat-flat-button class="premium-horizontal" [routerLink]="['/premium']">
                    <mat-icon iconPositionStart svgIcon="premium" class="sized" style="--icon-size: 22px;"></mat-icon>
                    Get Premium
                </a>
            </li>
        }
    </ul>
</nav>
<div class="language f-col f-cross-center" [@scrollDirection]="isVisible()" (click)="translate.use(translate.currentLang == 'mt' ? 'en' : 'mt')">
    <div class="selector" [class.moved]="translate.currentLang == 'mt'">

    </div>
    <button mat-icon-button>
        <mat-icon svgIcon="logo:england"></mat-icon>
    </button>
    <button mat-icon-button>
        <mat-icon svgIcon="logo:malta"></mat-icon>
    </button>
</div>

<nav class="mobile">
    <ul class="m-0 px-10 p-inset cgap-8 f-main-around f-cross-center">
        <li class="mx-5">
            <a mat-ripple class="py-5 p-inset f-col f-main-center f-cross-center no-decoration" [routerLink]="['/']" routerLinkActive="active-route" [routerLinkActiveOptions]="{ exact: true }">
                <mat-icon svgIcon="logo:cloudigo"></mat-icon>
                <small>Home</small>
            </a>
        </li>
        <li class="mx-5">
            <a mat-ripple class="py-5 p-inset f-col f-main-center f-cross-center no-decoration" [routerLink]="['/search']" routerLinkActive="active-route">
                <mat-icon>search</mat-icon>
                <small>Search</small>
            </a>    
        </li>
        @if(!authLoaded() || auth.userSession()){
            <li class="mx-5">
                <a mat-ripple class="py-5 p-inset f-col f-main-center f-cross-center no-decoration" [routerLink]="['/profile/list']" [class.active-route]="router.current.includes('/profile')">
                    <mat-icon>person</mat-icon>
                    <small>Profile</small>
                </a>    
            </li>
        } @else {
            <li class="mx-5">
                <a mat-ripple class="py-5 p-inset f-col f-main-center f-cross-center no-decoration" [routerLink]="['/login']" [class.active-route]="router.current.includes('/profile')">
                    <mat-icon>person</mat-icon>
                    <small>Login</small>
                </a>    
            </li>
        }
        @if(!auth.isPremium()){
            <li class="premium mx-5">
                <a mat-ripple class="py-5 p-inset f-col f-main-center f-cross-center no-decoration" [routerLink]="['/premium']">
                    <mat-icon svgIcon="premium"></mat-icon>
                    <small>Get Premium</small>
                </a>    
            </li>
        }
    </ul>
</nav>