@if(!location.isFromIframe() && !location.isFromApp()){
    <cloudigo-nav-bar/>
}
<canvas class="app-overlay" id="snowfall-element"></canvas>
<router-outlet/>
<div class="f-main-center initial-loader svg-loader">
    <object data="/old/cloudigo_loader.svg" type="image/svg+xml"></object>
</div>
@if(!location.isFromIframe() && !location.isFromApp()){
    <cloudigo-footer/>
}