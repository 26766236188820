import { ActivatedRouteSnapshot, CanActivateFn, RedirectCommand, Router, Routes } from '@angular/router';
import { app, business, images, travel } from '../environments/environment';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Auth } from '@angular/fire/auth';
import { from, lastValueFrom, map, of } from 'rxjs';

//Log user out and redirect to login when visiting this route
const logoutRerouter: CanActivateFn = async(snapshot: ActivatedRouteSnapshot) => {
  const loginPath = inject(Router).parseUrl('/');
  inject(AuthService).logout(snapshot?.queryParams?.reason ?? 'Visited Log out'); 
  return new RedirectCommand(loginPath);
};

//Redirect non-logged in users to login (and remembering their intent) when visiting this route
const authRerouter: CanActivateFn = async (snapshot: ActivatedRouteSnapshot) => {
  const platform = inject(Platform);

  if(!platform.isBrowser){
    return true;
  }

  const auth = inject(Auth);
  const router = inject(Router);

  const isLoggedIn = await lastValueFrom(from(auth.authStateReady()).pipe(map(() => !!auth.currentUser)));

  if(isLoggedIn){
    return true;
  }

  const fullPath = {
    route: `/${snapshot.url.join('/')}`,
    queryParams: { ... snapshot.queryParams },
    fragment: snapshot.fragment
  };
  
  sessionStorage.setItem('redirect', JSON.stringify(fullPath));

  const redirectPath = router.parseUrl('/login');
  return new RedirectCommand(redirectPath);
};

//Block users from visiting this route if they already have a premium membership
const checkoutBlocker: CanActivateFn = async (snapshot: ActivatedRouteSnapshot) => {
  const auth = inject(AuthService);
  const router = inject(Router);

  const alreadyInCheckout = router.url.startsWith('/checkout');
  const plan = snapshot.params.plan;

  const redirect = new RedirectCommand(router.parseUrl(router.url));

  if(plan != 'yearly' && !alreadyInCheckout){
    return redirect;
  }

  const isPremium = await lastValueFrom(from(auth.fireAuth?.authStateReady() || of(false)).pipe(map(() => auth.isPremium())));

  return !isPremium || redirect;
};

const snapshotToUrlData = (prefix: string, snapshot: Partial<ActivatedRouteSnapshot>) => {
  const queryParams = Object.entries(snapshot.queryParams || {});
  const fragment = snapshot.fragment;

  let url = prefix;

  if(queryParams && queryParams.length){
    url += `${url.includes('?') ? '&' : '?'}${queryParams.map(([key, value]) => `${key}=${value}`).join('&')}`
  }

  if(fragment){
    url += `#${fragment}`
  }

  return url;
}

export const routes: Routes = [
    { path: '', loadComponent: () => import('./old/homev2/homev2.component') },
    { path: 'loading', loadComponent: () => import('./loader/loader.component') },
    //#region Brand & Offer
    { path: 'premium/loading', redirectTo: (snapshot) => snapshotToUrlData(`/loading`, snapshot) },
    { path: 'search' , loadComponent: () => import('./search/search.component') },
    { path: 'brands/:slug', loadComponent: () => import('./brands/brand-page/brand-page.component') },
    { path: 'brands/:slug/offers/:url', loadComponent: () => import('./offers/offer-page/offer-page.component') },
    //#endregion
    
    //#region Auth & Profile
    { path: 'login', loadComponent: () => import('./old/auth/auth.component') },
    { path: 'register', redirectTo: (snapshot) => snapshotToUrlData('/login', snapshot) },
    { path: 'signup', redirectTo: (snapshot) => snapshotToUrlData('/login', snapshot) },
    { path: 'auth/*', redirectTo: (snapshot) => snapshotToUrlData('/login', snapshot) },
    { path: 'profile/handle', redirectTo: (snapshot) => snapshotToUrlData('/profile/account', snapshot) },
    { path: 'profile/:page', loadComponent: () => import('./old/profile/profile.component'), canActivate: [authRerouter] },
    { path: 'profile', redirectTo: (snapshot) => snapshotToUrlData('/profile/list', snapshot) },
    { path: 'verify-change', loadComponent: () => import('./old/auth/verify-change/verify-change.component') },
    { path: 'logout', loadComponent: () => import('./old/homev2/homev2.component'), canActivate: [logoutRerouter] },
    //#endregion

    //#region Info Pages
    { path: 'about-us', loadComponent: () => import('./old/about-us/about-us.component') },
    { path: 'contact-us', loadComponent: () => import('./old/contact-us/contact-us.component') },
    { path: 'get-the-app', loadComponent: () => import('./old/info/get-app/get-app.component') },
    { path: 'how-to/redeem', loadComponent: () => import('./old/info/use-guide/use-guide.component') },
    { path: 'how-to/premium', loadComponent: () => import('./old/info/coupon-guide/coupon-guide.component') },
    { path: 'how-to/gift', loadComponent: () => import('./old/info/redeem-guide/redeem-guide.component') },
    { path: 'faqs', loadComponent: () => import('./old/info/faq.component') },
    { path: 'faqs-mt', loadComponent: () => import('./old/info/faq-mt.component') },
    { path: 'privacy-policy', loadComponent: () => import('./old/info/privacy.component') },
    { path: 'privacy', redirectTo: (snapshot) => snapshotToUrlData('/privacy-policy', snapshot) },
    { path: 'privacy-policy-b2b', loadComponent: () => import('./old/info/privacy-b2b.component') },
    { path: 'privacy-b2b', redirectTo: '/privacy-policy-b2b' },
    { path: 'subprocessors', loadComponent: () => import('./old/info/sub-processors.component') },
    { path: 'sub-processors', redirectTo: '/subprocessors' },
    { path: 'terms-and-conditions', loadComponent: () => import('./old/info/terms.component') },
    { path: 'terms-and-conditions/discounts', loadComponent: () => import('./old/info/terms-coupon.component') },
    { path: 'terms-and-conditions/campaigns', loadComponent: () => import('./old/info/terms-campaign.component') },
    { path: 'terms-and-conditions/social', redirectTo: (snapshot) => snapshotToUrlData('/terms-and-conditions/campaigns', snapshot) },
    { path: 'terms/:path', redirectTo: (snapshot) => snapshotToUrlData(`/terms-and-conditions/${snapshot.params.path}`, snapshot) },
    { path: 'terms', redirectTo: (snapshot) => snapshotToUrlData('/terms-and-conditions', snapshot) },
    { path: 'cookie-policy', loadComponent: () => import('./old/info/cookies.component') },
    { path: 'cookies', redirectTo: (snapshot) => snapshotToUrlData('/cookie-policy', snapshot) },
    { path: 'opted-out', loadComponent: () => import('./old/info/email-optout/email-optout.component') },
    { path: 'opted-out/:email', redirectTo: (route) => `/opted-out?email=${route.params.email}` },
    { path: 'info/:path', redirectTo: (snapshot) => snapshotToUrlData(`/${snapshot.params.path}`, snapshot) },
    //#endregion

    //#region Campaigns
    { path: 'game/:path', loadComponent: () => import('./old/campaign/campaign.component') },
    { path: 'invite/:path', loadComponent: () => import('./old/game-invite/game-invite.component') },
    { path: 'e/invite/:path', redirectTo: (snapshot) => snapshotToUrlData(`/invite/${snapshot.params.path}`, snapshot) },
    //#endregion

    //#region Refer-A-Friend
    { path: 'refer-a-friend', loadComponent: () => import('./old/campaign/referral/referral.component') },
    { path: 'bring-a-friend', loadComponent: () => import('./old/bring-a-friend/bring-a-friend.component') },
    //#endregion

    //#region Builder
    { path: 'builder', loadComponent: () => import('./builder/builder.component') },
    { path: 'b/:name', loadComponent: () => import('./builder/builder.component') },
    //#endregion

    //#region Distributions
    { path: 'gift', loadComponent: () => import('./old/gift-preview/gift-preview.component') },
    { path: 'gift/redeem', loadComponent: () => import('./old/gift-claim/gift-claim.component'), canActivate: [authRerouter] },
    { path: 'gift/claim', redirectTo: (snapshot) => snapshotToUrlData('/gift', snapshot) },
    { path: 'gift/claim/welcome', redirectTo: (snapshot) => snapshotToUrlData('/gift/redeem', snapshot) },
    //#endregion

    //#region Premium
    { path: 'premium', loadComponent: () => import('./old/get-premium/get-premium.component') },
    { path: 'premium/gift', loadComponent: () => import('./old/get-premium/get-premium.component'), data: { is_retail: true } },
    { path: 'premium/yearly', redirectTo: (snapshot) => snapshotToUrlData('/premium', snapshot) },
    { path: 'yearly', redirectTo: (snapshot) => snapshotToUrlData('/premium', snapshot) },
    { path: 'premium/:promo', loadComponent: () => import('./old/get-premium/get-premium.component') },
    { path: 'premium/gift', loadComponent: () => import('./old/get-premium/get-premium.component'), data: { is_retail: true } },
    { path: 'premium/gift/:promo', loadComponent: () => import('./old/get-premium/get-premium.component'), data: { is_retail: true } },
    { path: 'catalogue/:plan', loadComponent: () => import('./old/catalogue-premium/catalogue-premium.component') },
    { path: 'premium/catalogue/:plan', redirectTo: (snapshot) => snapshotToUrlData(`/catalogue/${snapshot.params.plan}`, snapshot) },

    // { path: 'gift', loadComponent: () => import('./old/get-gift-premium/get-gift-premium.component') },

    
    //Checkouts
    { path: 'checkout/:plan', loadComponent: () => import('./package-checkout/package-checkout.component'), canActivate: [authRerouter,checkoutBlocker] },
    { path: 'post-checkout', loadComponent: () => import('./package-checkout-confirm/package-checkout-confirm.component') },
    { path: 'yearly/checkout', redirectTo: (snapshot) => snapshotToUrlData('/checkout/yearly', snapshot) },
    { path: 'checkout', redirectTo: (snapshot) => snapshotToUrlData('/checkout/yearly', snapshot) },
    { path: 'purchase/:plan', loadComponent: () => import('./coupon-checkout/coupon-checkout.component') },
    { path: 'premium/purchase', redirectTo: (snapshot) => snapshotToUrlData('/purchase/traveller', snapshot) },
    { path: 'post-purchase', loadComponent: () => import('./old/coupon-checkout-confirm/coupon-checkout-confirm.component') },

    //#endregion

    //#region Tourist
    { path: 'booking/bokun', loadComponent: () => import('./old/redemption-portal/redemption-portal.component').then(({ RedemptionPortalBokunComponent }) => RedemptionPortalBokunComponent) },
    { path: 'booking/malta-excursions', loadComponent: () => import('./old/redemption-portal/redemption-portal.component').then(({ RedemptionPortalMaltaExcursionsComponent }) => RedemptionPortalMaltaExcursionsComponent) },
    { path: 'menus', loadComponent: () => import('./old/menus/menus.component'), children: [
      { path: '', loadComponent: () => import('./old/menus/menu-browser/menu-browser.component') },
      { path: ':brand', loadComponent: () => import('./old/menus/menu-outlet/menu-outlet.component') },
      { path: 'store/:store', loadComponent: () => import('./old/menus/menu-outlet/menu-outlet.component') },
    ] },
    //#endregion

    //#region External URLs
    { 
      path: 'traveller', 
      loadComponent: () => import('./old/homev2/homev2.component'),
      resolve: { url: 'externalUrlRedirectResolver'}, 
      data: { externalUrl: travel },
      children: [
        {
          path: '**',
          loadComponent: () => import('./old/homev2/homev2.component'),
        }
      ] 
    },
    { 
      path: 'business', 
      loadComponent: () => import('./old/homev2/homev2.component'),
      resolve: { url: 'externalUrlRedirectResolver'}, 
      data: { externalUrl: business },
      children: [
        {
          path: '**',
          loadComponent: () => import('./old/homev2/homev2.component'),
        }
      ] 
    },
    { 
      path: 'images', 
      loadComponent: () => import('./old/homev2/homev2.component'),
      resolve: { url: 'externalUrlRedirectResolver'}, 
      data: { externalUrl: images },
      children: [
        {
          path: '**',
          loadComponent: () => import('./old/homev2/homev2.component'),
        }
      ] 
    },
    {
      path: 'android',
      loadComponent: () => import('./old/homev2/homev2.component'),
      resolve: { url: 'externalUrlRedirectResolver' },
      data: { externalUrl: `https://play.google.com/store/apps/details?id=${app.package}` },
    },
    {
      path: 'ios',
      loadComponent: () => import('./old/homev2/homev2.component'),
      resolve: { url: 'externalUrlRedirectResolver' },
      data: { externalUrl: `https://apps.apple.com/mt/app/cloudigo/id${app.id}` },
    },
    //#endregion

    { path: '**', redirectTo: '/' }
];
